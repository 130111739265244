import { default as indexgpmdwwRWO1Meta } from "/home/deploy/salsa-web/pages/account/index.vue?macro=true";
import { default as indexS06EAVOa8WMeta } from "/home/deploy/salsa-web/pages/auth/index.vue?macro=true";
import { default as login4DswlM7umHMeta } from "/home/deploy/salsa-web/pages/auth/login.vue?macro=true";
import { default as signupQyJpHfnqfYMeta } from "/home/deploy/salsa-web/pages/auth/signup.vue?macro=true";
import { default as indexpizn8151RgMeta } from "/home/deploy/salsa-web/pages/index.vue?macro=true";
export default [
  {
    name: "account___fr",
    path: "/mon-compte",
    component: () => import("/home/deploy/salsa-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/my-account",
    component: () => import("/home/deploy/salsa-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "auth___fr",
    path: "/connexion",
    component: () => import("/home/deploy/salsa-web/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/home/deploy/salsa-web/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___fr",
    path: "/connexion/se-connecter",
    component: () => import("/home/deploy/salsa-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    component: () => import("/home/deploy/salsa-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___fr",
    path: "/connexion/s-inscrire",
    component: () => import("/home/deploy/salsa-web/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___en",
    path: "/en/auth/signup",
    component: () => import("/home/deploy/salsa-web/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/home/deploy/salsa-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/deploy/salsa-web/pages/index.vue").then(m => m.default || m)
  }
]