

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"display":{"mobileBreakpoint":"sm"},"theme":{"defaultTheme":"myCustomLightTheme","themes":{"myCustomLightTheme":{"dark":false,"colors":{"on-surface":"#333A73","primary":"#FC6736","on-primary":"#FFFFFF","secondary":"#333A73","on-secondary":"#FFFFFF","white":"#FFFFFF"}}}},"ssr":true}')
  
  
  
  
  return options
}

