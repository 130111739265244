import validate from "/home/deploy/salsa-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import authenticated_45global from "/home/deploy/salsa-web/middleware/authenticated.global.ts";
import not_45authenticated_45global from "/home/deploy/salsa-web/middleware/not-authenticated.global.ts";
import manifest_45route_45rule from "/home/deploy/salsa-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authenticated_45global,
  not_45authenticated_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}